<!-- 
	This is the Timeline page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>

  <div id="settings">
    <a-tabs class="tabs-sliding" default-active-key="1" style="margin-bottom: 24px;">
      <a-tab-pane key="1" tab="任务参数">
        <a-card class="header-solid" :bodyStyle="{padding: '30px'}">
          <a-descriptions bordered id="basic">
            <a-descriptions-item label="跟单平台">
              {{t_p}}
            </a-descriptions-item>
            <a-descriptions-item label="跟单对象">
              {{uniqueName}}
            </a-descriptions-item>
            <a-descriptions-item label="创建时间">
              {{create_datetime}}
            </a-descriptions-item>
            <a-descriptions-item label="反向跟单">
              {{posSide_set == '1'?'否':'是'}}
            </a-descriptions-item>
            <a-descriptions-item label="API">
              {{api_name}}
            </a-descriptions-item>
            <a-descriptions-item label="交易员类型">
              {{r_t}}
            </a-descriptions-item>
            <a-descriptions-item label="跟单模式">
              {{f_t}}
            </a-descriptions-item>
            <a-descriptions-item v-if="follow_type == '1'" label="单笔跟单金额">
              {{sums}} USDT
            </a-descriptions-item>
            <a-descriptions-item v-if="f_t == '固定比例'" label="跟单比例">
              {{ratio}}
            </a-descriptions-item>
            <a-descriptions-item v-if="f_t == '智能跟单'" label="智能倍数">
              {{multiple}}
            </a-descriptions-item>
            <a-descriptions-item v-if="f_t == '智能跟单'" label="投资额">
              {{investment}}
            </a-descriptions-item>
            <a-descriptions-item v-if="f_t == '固定金额' && role_type == '2'" label="单次减仓比例">
              {{reduce_ratio*100}}%
            </a-descriptions-item>
            <a-descriptions-item label="杠杆设置">
              {{lever_set == '1'?'跟随交易员':leverage}}
            </a-descriptions-item>
            <a-descriptions-item v-if="trade_trigger_mode == '1'" label="单笔止盈">
              {{tp_trigger_px == '0'?'未设置':tp_trigger_px+'%'}}
            </a-descriptions-item>>
            <a-descriptions-item v-if="trade_trigger_mode == '1'" label="单笔止损">
              {{sl_trigger_px == '0'?'未设置':sl_trigger_px+'%'}}
            </a-descriptions-item>
            <a-descriptions-item v-if="first_open_type == '2'" label="区间委托(新开仓)">
              收益率小于{{uplRatio}}%时跟单
            </a-descriptions-item>
            <a-descriptions-item label="任务状态" :span="3">
              <a-badge v-if="status === 1" status="processing" text="Running" />
              <a-badge v-if="status !== 1" status="error" text="Stop" />
              <a-button v-if="status === 1" type="danger" :size="small" class="stop_btn" @click="stopTask()">终止跟单</a-button>
            </a-descriptions-item>
          </a-descriptions>
        </a-card>
      </a-tab-pane>
      <!-- Social Tab -->
      <a-tab-pane key="2" tab="当前仓位">

          <TradeTable
            :data="positionData"
            :columns="positionColumns"
          ></TradeTable>

      </a-tab-pane>
      <!-- / Social Tab -->

      <!-- Notifications Tab -->
      <a-tab-pane key="3" tab="历史仓位">

        <TradeHistoryTable
            :data="historyData"
            :columns="historyColumns"
        ></TradeHistoryTable>

      </a-tab-pane>
      <!-- / Notifications Tab -->

    </a-tabs>
    <a-row type="flex" :gutter="[24,24]">

      <a-col :span="24" :lg="12">

        <!-- Orders History Timeline Card -->
        <a-card :bordered="false" class="header-solid mb-24" :bodyStyle="{paddingTop: '12px',}">
          <template #title>
            <div style="display: flex;  align-items: center;">
              <a-icon type="bug" theme="outlined" class="svg"/>
              <h6 style="display: inline-block; margin-left: 10px">交易员交易记录</h6>
            </div>
          </template>
          <a-timeline>
            <a-timeline-item :color="item.color" v-for="item in spiderData" :key="item.date">
              {{ item.title }}
              <small>{{ item.date }}</small>
              <p>
                {{ item.description }}
              </p>
            </a-timeline-item>
          </a-timeline>
        </a-card>
        <!-- / Orders History Timeline Card -->

      </a-col>

      <a-col :span="24" :lg="12">

        <!-- Orders History Timeline Card -->
        <a-card :bordered="false" class="bg-gray-10 header-solid mb-24" :bodyStyle="{paddingTop: '12px',}">
          <template #title>
            <div style="display: flex;  align-items: center;">
              <img src="/images/white-logo.png" style="width: 33px">
              <h6 class="text-white" style="display: inline-block;margin-left: 10px">跟单猿跟单记录</h6>
              <p v-if="posSide_set === 2" style="display: inline;background-color: #ec575b;color: white;border-radius: 2px;margin-left: 5px">&nbsp;&nbsp;反向跟单&nbsp;&nbsp;</p>
            </div>
          </template>
          <a-timeline class="timeline-dark">
            <a-timeline-item :color="item.color" v-for="item in tradeData" :key="item.date">
            {{ item.title }}
            <small>{{ item.date }}</small>
            <p>
              {{ item.description }}
            </p>
          </a-timeline-item>
          </a-timeline>
        </a-card>
        <!-- / Orders History Timeline Card -->

      </a-col>

    </a-row>

  </div>

</template>

<script>

import http from "@/utils/http";
import TradeTable from "@/components/Cards/TradeTable";
import TradeHistoryTable from "@/components/Cards/TradeHistoryTable";

const positionColumns = [
  {
    title: '当前仓位',
    dataIndex: 'position',
    scopedSlots: { customRender: 'position' },
  },
];
const historyColumns = [
  {
    title: '历史仓位',
    dataIndex: 'history',
    scopedSlots: { customRender: 'history' },
  },
];

function formatData(item) {
    const cTime = new Date(item.cTime);
    const formatTime = `${cTime.getFullYear()}/${(cTime.getMonth() + 1).toString().padStart(2, '0')}/${cTime.getDate().toString().padStart(2, '0')} ${cTime.getHours().toString().padStart(2, '0')}:${cTime.getMinutes().toString().padStart(2, '0')}:${cTime.getSeconds().toString().padStart(2, '0')}`;
    const formatPnl = parseFloat(item.pnl).toFixed(2);
    const formatUpl = parseFloat(item.upl).toFixed(2);
    const formatPnlRatio = parseFloat(item.pnlRatio * 100).toFixed(2);
    const formatUplRatio = parseFloat(item.uplRatio * 100).toFixed(2);
    const formatimr = parseFloat(item.imr).toFixed(2);
    const formatopen = parseFloat(item.openAvgPx).toFixed(2);

    return {
      position: {
        instId: item.instId,
        mgnMode: item.mgnMode,
        cTime: formatTime,
        lever: item.lever,
        posSide: item.posSide,
        imr: formatimr,
        openAvgPx: formatopen,
        pnl: formatPnl,
        upl: formatUpl,
        pnlRatio: formatPnlRatio,
        uplRatio: formatUplRatio,
        posSide_set: item.posSide_set,
      }
    };
}
function formathistorytData(item) {

    const cTime = new Date(item.cTime);
    const uTime = new Date(item.uTime);
    const formatcTime = `${cTime.getFullYear()}/${(cTime.getMonth() + 1).toString().padStart(2, '0')}/${cTime.getDate().toString().padStart(2, '0')} ${cTime.getHours().toString().padStart(2, '0')}:${cTime.getMinutes().toString().padStart(2, '0')}:${cTime.getSeconds().toString().padStart(2, '0')}`;
    const formatuTime = `${uTime.getFullYear()}/${(uTime.getMonth() + 1).toString().padStart(2, '0')}/${uTime.getDate().toString().padStart(2, '0')} ${uTime.getHours().toString().padStart(2, '0')}:${uTime.getMinutes().toString().padStart(2, '0')}:${uTime.getSeconds().toString().padStart(2, '0')}`;
    const formatPnl = parseFloat(item.pnl).toFixed(2);
    const formatUpl = parseFloat(item.upl).toFixed(2);
    const formatPnlRatio = parseFloat(item.pnlRatio * 100).toFixed(2);
    const formatUplRatio = parseFloat(item.uplRatio * 100).toFixed(2);
    const formatimr = parseFloat(item.imr).toFixed(2);
    const formatopen = parseFloat(item.openAvgPx).toFixed(2);
    const formatclose = parseFloat(item.closeAvgPx).toFixed(2);

    return {
      history: {
        instId: item.instId,
        mgnMode: item.mgnMode,
        posSide: item.posSide,
        cTime: formatcTime,
        uTime: formatuTime,
        lever: item.lever,
        imr: formatimr,
        openAvgPx: formatopen,
        closeAvgPx: formatclose,
        pnl: formatPnl,
        upl: formatUpl,
        pnlRatio: formatPnlRatio,
        uplRatio: formatUplRatio,
      }
    };
}


function transformItem(item){
  if(item.color == 'SUCCESS'){
    item.color ='green';
  }else if(item.color == 'WARNING'){
    item.color = 'danger';
  }else if(item.color == 'INFO'){
    item.color = 'primary';
  }
  return {
    title: item.title,
    date: item.date,
    description: item.description,
    color: item.color,
  };
}

export default {
  components: {
    TradeTable,
    TradeHistoryTable,
  },
  data() {
    return {
      posSide_set: '',
      status: '',
      uniqueName: '',
      lever_set: '',
      leverage: '',
      sums: '',
      multiple: '',
      t_p: '', // 跟单平台
      trader_platform: '',
      first_order_set: '',
      api_name: '',
      f_t: '', // 跟单类型
      follow_type: '',
      create_datetime: '',
      role_type: '',
      r_t: '', // 跟单角色
      reduce_ratio: '',
      ratio: '',
      investment: '',
      trade_trigger_mode: '',
      sl_trigger_px: '',
      tp_trigger_px: '',
      uplRatio: '',
      first_open_type: '',

      positionData: [],
      historyData: [],
      positionColumns: positionColumns,
      historyColumns: historyColumns,

      spiderData: [], // 爬虫数据
      tradeData: [],  // 交易数据
    };
  },

  created() {
    const id = this.$route.params.id;
    if (id) {
      this.loadData(id);
      this.loadTradeData(id);
      this.loadStatus(id);
    } else {
      // 处理没有 key 的情况
      this.$router.push("/tasklist");
    }
  },
  methods: {
    transTP() {
      if(this.trader_platform == '1'){
        return 'OKX';
      }else if(this.trader_platform == '2'){
        return 'Binance';
      }else if(this.trader_platform == '3'){
        return '币coin';
      }else if(this.trader_platform == '4'){
        return '热门交易员';
      }else if(this.trader_platform == '5'){
        return 'Bitget';
      }else if(this.trader_platform == '6'){
        return 'API';
      }else if(this.trader_platform == '7'){
        return 'Binance';
      }
    },
    transFT() {
      if(this.trader_platform == '1' && this.role_type == '1'){
        if(this.follow_type == '1'){
          return '固定金额';
        } else if(this.follow_type == '2'){
          return '固定比例';
        }
      }else if(this.trader_platform == '1' && this.role_type == '1'){
        if(this.follow_type == '1'){
          return '固定金额';
        } else if(this.follow_type == '2'){
          return '智能跟单';
        }
      }else if(this.trader_platform == '2'){
        if(this.follow_type == '2'){
          return '智能跟单';
        } else {
          return '固定金额';
        }
      }else {
        if(this.follow_type == '1'){
          return '固定金额';
        } else if(this.follow_type == '2'){
          return '智能跟单';
        }
      }
    },
    transRT() {
      if(this.role_type == '1' && this.trader_platform == '1') {
        return '带单员';
      }else if(this.role_type == '2' && this.trader_platform == '1'){
        return '普通用户';
      }else if(this.role_type == '1' && this.trader_platform == '2'){
        return '公开实盘';
      }else if(this.role_type == '2' && this.trader_platform == '2'){
        return '隐藏实盘';
      }else if(this.role_type == '1' && this.trader_platform == '3'){
        return '操作记录';
      }else {
        return '交易员';
      }
    },
    async loadStatus(id) {
      try {
        const response = await http.get("/api/taskadd/" + id + "/?token=" + localStorage.getItem("token"));
        this.status = response.data.status;
        this.posSide_set = response.data.posSide_set;
        this.lever_set = response.data.lever_set;
        this.leverage = response.data.leverage;
        this.sums = response.data.sums;
        this.multiple = response.data.multiple;
        this.first_order_set = response.data.first_order_set;
        this.trader_platform = response.data.trader_platform;
        this.uniqueName = response.data.uniqueName;
        this.api_name = response.data.api_name;
        this.follow_type = response.data.follow_type;
        this.create_datetime = response.data.create_datetime.replace('T', '  ');
        this.role_type = response.data.role_type;
        this.reduce_ratio = response.data.reduce_ratio;
        this.ratio = response.data.ratio;
        this.investment = response.data.investment;
        this.trade_trigger_mode = response.data.trade_trigger_mode;
        this.sl_trigger_px = response.data.sl_trigger_px;
        this.tp_trigger_px = response.data.tp_trigger_px;
        this.uplRatio = response.data.uplRatio;
        this.first_open_type = response.data.first_open_type;

        this.t_p = this.transTP();
        this.f_t = this.transFT();
        this.r_t = this.transRT();
        // console.log('trade_trigger_mode:', this.trade_trigger_mode);
      }catch (error) {
        console.log('更新失败:', error); // 错误处理
      }
    },
    async stopTask() {
      this.$confirm({
        title: '确认终止',
        content: '终止任务不会进行平仓，当前如有持仓，后续请自行平仓。',
        okText: '确认',
        cancelText: '取消',
        onOk: async () => {
          try {
            await http.patch("/api/taskadd/" + this.$route.params.id + "/?token=" + localStorage.getItem("token"), {"task_id": this.$route.params.id,"status": "2"});
            // 刷新当前页面
            window.location.reload();
          } catch (error) {
            console.error('更新失败:', error); // 错误处理
          }
        },
        onCancel() {
          console.log('终止操作已取消');
        },
      });
    },
    async loadData(id) {
      const url = '/api/traderdetial/'+id+'/?token='+localStorage.getItem("token");
      http.get(url).then(response => {
        if (response.data.code!== 0) {
          this.$message.error(response.data.detail);
          this.$router.push("/tasklist");
          return;
        }
        this.tradeData = response.data.data.trade.map(item => transformItem(item));
        this.spiderData = response.data.data.spider.map(item => transformItem(item));
      }).catch(error => {
        console.error(error);
      });
    },
    async loadTradeData(id) {
      const url = '/api/tradeorder/' + id + '/?token=' + localStorage.getItem("token");
      http.get(url).then(response => {
        const dataArray = response.data.data;
        const status1 = []
        const status2 = []
        dataArray.forEach(item => {
          if (item.status === 1) {
            // 如果 status 为 1，将数据添加到 status1
            status1.push(item);
          } else if (item.status === 2) {
            // 如果 status 为 2，将数据添加到 status2
            status2.push(item);
          }
        });
        this.positionData = status1.map(item => formatData(item));
        this.historyData = status2.map(item => formathistorytData(item));
      }).catch(error => {
        console.error(error);
      });
    },




  },
};
</script>

<style lang="scss">
.stop_btn {
  margin-left: 20px;
  height: 32px;
  //position: absolute;
  //right: 20px;
  //top: 10px;
  //z-index: 101;
}
.ant-timeline-item-head-danger {
  color: #f5222d;
  border-color: #f5222d;
}

.ant-timeline-item-head-primary {
  color: #1890ff;
  border-color: #1890ff;
}

.ant-timeline-item-head-warning {
  color: #fadb14;
  border-color: #fadb14;
}

.ant-timeline-item-head-secondary {
  color: #b37feb;
  border-color: #b37feb;
}

.ant-timeline-item-head-dark {
  color: #141414;
  border-color: #141414;
}

.ant-timeline-item-head-success {
  olor: #52c41a;
  border-color: #52c41a;
}

.ant-timeline-item-content {
  margin: 0 0 0 33px;
  font-size: 14px;
  top: -4px;
  font-weight: 600;
  color: #141414;
}

.ant-timeline-item-content small {
  display: block;
  font-size: 12px;
  font-weight: 700;
  color: #8c8c8c;
  margin: 0;
}

.ant-timeline-item-content p {
  color: #8c8c8c;
  font-weight: 400;
  margin-top: 10px;
  font-size: 14px;
}

.timeline-dark .ant-timeline-item-content {
  color: #fff;
}

.timeline-dark .ant-timeline-item-content p, .timeline-dark .ant-timeline-item-content small {
  color: #d9d9d9;
}

.timeline-dark .ant-timeline-item-content p {
  color: #d9d9d9;
  font-weight: 400;
  margin-top: 10px;
  font-size: 14px;
}

.timeline-dark .ant-timeline-item-head {
  background-color: #262626;
}

.svg svg {
  width: 25px;
  height: 25px;
}

#settings::v-deep {
  .ant-list {
    width: 100%;
  }
  .ant-list-item-meta-avatar {
    margin-right: 8px;
  }
  .ant-list-item-meta {
    align-items: center;
  }
  .ant-list-item-meta-title {
    margin: 0;
  }

  .ant-anchor-ink::before {
    display: none;
  }
  .ant-anchor-link {
    padding: 0;
    margin-top: 8px;
  }
  .ant-anchor-link a {
    width: 100%;
    border-radius: 8px;
    color: #67748e !important;
    padding: 10px 16px;
    background-color: transparent;
    transition: background-color 0.3s ease-in;
  }
  .ant-anchor-link a:hover {
    background-color: #eeeeee;
  }
  .ant-anchor-link a svg g {
    fill: #344767;
  }
  .ant-anchor-link a svg {
    margin-right: 8px;
  }

  .card-profile-head {
    margin: 0 0 24px;
  }
  .tags-field .ant-form-item-control {
    line-height: 33px;
  }
  .form-tag.ant-tag {
    border-radius: 20px;
    padding: 4px 10px;
    font-size: 12px;
    font-weight: 500;
    margin-right: 3.75px;
    margin-bottom: 3.75px;
    background-color: #3a416f;
    border: 1px solid #3a416f;
    color: #fff;
  }
  .form-tag.ant-tag .anticon-close {
    color: #fff;
    height: 16px;
    border-left: 1px solid hsla(0,0%,100%,.3);
    padding-left: 5px;
    padding-top: 2px;
    opacity: .75;
  }
  .form-tag.ant-tag .anticon-close:hover {
    color: #fff;
    opacity: 1;
  }
  .tags-field .ant-input {
    margin-bottom: 5px;
    margin-top: 4px;
  }

  .tags-field .ant-select {
    .ant-select-selection__choice__remove i {
      color: #fff;
      height: 16px;
      border-left: 1px solid hsla(0,0%,100%,.3);
      padding-left: 5px;
      padding-top: 2px;
      opacity: .75;

      &:hover {
        color: #fff;
        opacity: 1;
      }
    }
    .ant-select-selection__rendered > ul > li:not(.ant-select-search) {
      border-radius: 20px;
      padding: 2px 27px 2px 10px;
      font-size: 12px;
      font-weight: 500;
      margin-right: 3.75px;
      margin-bottom: 3.75px;
      background-color: #3a416f;
      border: 1px solid #3a416f;
      color: #fff;
      line-height: 2;
      height: 30px;
    }
    .ant-select-selection--multiple {
      padding: 8px 10px;
    }
  }
}

#basic > div > table > tbody .ant-descriptions-row > th,
#basic > div > table > tbody .ant-descriptions-row > td {
  padding-bottom: 16px !important;
}
</style>